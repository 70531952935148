import { toRef, ref, computed } from 'vue'
import { useNuxtApp } from '#app'
import { useAPI, useHydrationStore, useSentry, useHydrationRef } from '#imports'
import { MIN_LAPTOP_WIDTH, useDevice } from '@/composables/device'
import { useDebounceFn } from '@vueuse/core'
import type { Product } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'
import type { Cart, BasicCart, BasicCartItem, CartItem } from '@winestyle/api-client/src/ts/api/sales/v1/cart_pb.js'
import type { Totals } from '@winestyle/api-client/src/ts/api/sales/v1/common_pb.js'
// import { useProductStore } from '@/stores/product'

type State = {
  loadingId: number | null
  popoverCartShow: boolean
  isProductRequested: boolean
  helpProduct: Product.AsObject | undefined
}

class CartClickError extends Error {
  productId: number | undefined

  constructor (message: string, productId?: number) {
    super(message)

    this.productId = productId
  }
}

// TODO request sending on multiple click
export function useCartStore () {
  const nuxtApp = useNuxtApp()
  const api = useAPI()
  const sentry = useSentry()
  const state = useHydrationStore<State>('cart-store', {
    loadingId: null,
    popoverCartShow: false,
    isProductRequested: false,
    helpProduct: undefined
  }, { disableHydration: true })
  const cartProducts = useHydrationRef<CartItem.AsObject[]>('cart-products', [])
  const cartItems = useHydrationRef<BasicCartItem.AsObject[]>('cart-items', [])
  const cartTotals = useHydrationRef<Totals.AsObject>('cart-totals', {
    totalBasePrice: undefined,
    totalDiscount: undefined,
    totalBonuses: undefined,
    receivingPrice: undefined,
    total: undefined
  })
  const cartItemsQuantity = useHydrationRef<number>('cart-items-quantity', 0)
  const cartProductsQuantity = useHydrationRef<number>('cart-products-quantity', 0)

  const cartIdToSet = ref()
  const amountToSet = ref()

  const setItemsQuantity = useDebounceFn(() => {
    const { setItemQuantityInMyCart, removeItemsFromMyCart } = api.cart()

    if (cartIdToSet.value && amountToSet.value === 0) {
      return removeItemsFromMyCart([cartIdToSet.value])
    }

    return setItemQuantityInMyCart(cartIdToSet.value, amountToSet.value)
  }, 300)

  function convertCartData (cart: Cart.AsObject) {
    cartProducts.value = cart?.itemsList || []

    if (cart?.totals) {
      cartTotals.value = cart?.totals
    }

    cartItemsQuantity.value = cartProducts.value.length
    cartItems.value = cartProducts.value.map(item => ({
      id: item.id,
      productId: item.product?.id || 0,
      quantity: item.quantity
    }))
    cartProductsQuantity.value = cartItems.value.reduce((acc, item) => {
      acc += item.quantity
      return acc
    }, 0)
  }

  function convertBasicCartData (cart: BasicCart.AsObject) {
    if (cart?.totals) {
      cartTotals.value = cart?.totals
    }

    cartItems.value = cart?.itemsList || []
    cartProductsQuantity.value = cartItems.value.reduce((acc, item) => {
      acc += item.quantity
      return acc
    }, 0)
  }

  function sendCartEvent (_eventName: string, _info = {}) {
    // if (nuxtApp.$sentry) {
    //   const { singleEventLogger } = nuxtApp.$sentry
    //   singleEventLogger(eventName, 'message', undefined, info)
    // }
  }

  function sendUnexpectedError (message: string, id?: number) {
    const err = new CartClickError(message, id)
    sentry.captureException(err)
  }

  function popoverShow (productId: number) {
    const isMobile = window?.innerWidth < MIN_LAPTOP_WIDTH
    if (!isMobile && state.value.loadingId === productId) {
      state.value.popoverCartShow = true
      const timeout = setTimeout(() => {
        state.value.popoverCartShow = false
        clearTimeout(timeout)
      }, 1500)
    }
  }

  async function getCart () {
    const { isCrawler } = useDevice()

    if (isCrawler) { return }

    try {
      const { getMyCart } = api.cart()
      const cart = await nuxtApp.runWithContext(() => useSharedPromise(`cart`, getMyCart))

      if (cart) {
        convertCartData(cart)
      }
    } catch {}
  }

  async function getCartBase () {
    const { isCrawler } = useDevice()

    if (isCrawler) { return }

    try {
      const { getMyCartBasic } = api.cart()
      const cart = await nuxtApp.runWithContext(() => useSharedPromise(`cart-base`, getMyCartBasic))

      if (cart) {
        convertBasicCartData(cart)
      }
    } catch {}
  }

  // async function getNapkins () {
  //   if (state.value.isProductRequested) { return }
  //   try {
  //     state.value.isProductRequested = true
  //     // const { getProduct } = useProductStore()
  //     // const { product } = await getProduct('napkins', false)
  //     // TODO: раскоментировать после мск
  //     // state.value.helpProduct = product
  //   } catch {}
  // }

  async function addProductToCart (id?: number, quantity = 1, timeout: NodeJS.Timeout | undefined = undefined) {
    const clickTimeout = timeout ?? setTimeout(() => {
      sendUnexpectedError('add product to cart error', id)
      clearTimeout(clickTimeout)
    }, 10000)

    if (id === undefined) {
      clearTimeout(clickTimeout)
      return
    }

    state.value.loadingId = id
    const { addProductToMyCart } = api.cart()

    try {
      const cart = await addProductToMyCart(id, quantity)
      clearTimeout(clickTimeout)

      nuxtApp.runWithContext(() => {
        if (nuxtApp.$google) {
          nuxtApp.$google.event('add_to_cart', {
            id,
            quantity
          })
        }
      })

      state.value.popoverCartShow = false

      if (cart) {
        convertBasicCartData(cart)
      }

      popoverShow(id)
    } catch (_err) {
      clearTimeout(clickTimeout)
    } finally {
      state.value.loadingId = null
    }
  }

  async function removeProductFromCart (ids: number[]) {
    try {
      const { removeItemsFromMyCart } = api.cart()
      const cart = await removeItemsFromMyCart(ids)

      if (cart) {
        convertBasicCartData(cart)
      }

      cartItems.value = cartItems.value.filter(item => !ids.includes(item.id))
    } catch {}
  }

  // async function removeSelectedProducts () {
  //   if (cartItems.value.length === cartProductsSelected.value.length) {
  //     await emptyCart()
  //     return
  //   }
  //
  //   await removeProductFromCart(cartProductsSelected.value)
  // }
  //
  // async function removeDisabledProducts () {
  //   if (cartItems.value.length === cartProductsDisabled.value.length) {
  //     await emptyCart()
  //     return
  //   }
  //
  //   await removeProductFromCart(cartProductsDisabled.value)
  // }

  async function setCartProductAmount ({ id, amount, timeout }: { id: number | undefined, amount: number, timeout?: NodeJS.Timeout }) {
    if (id === undefined) { return }

    const cartId = cartItems.value.find(el => el?.productId === id)?.id

    if (cartId === undefined) {
      await addProductToCart(id, amount, timeout)
      return
    }

    state.value.loadingId = id
    cartIdToSet.value = cartId
    amountToSet.value = amount

    try {
      const cart = await setItemsQuantity()

      if (cart) {
        convertBasicCartData(cart)
      }
    } finally {
      state.value.loadingId = null
    }
  }

  async function emptyCart () {
    try {
      const { emptyMyCart } = api.cart()
      const cart = await emptyMyCart()

      cartItems.value = []
      cartProducts.value = []
      cartTotals.value = cart?.totals || {
        totalBasePrice: undefined,
        totalDiscount: undefined,
        totalBonuses: undefined,
        receivingPrice: undefined,
        total: undefined
      }
      cartItemsQuantity.value = 0
      cartProductsQuantity.value = 0
    } catch {}
  }

  async function selectCartProduct (ids: number[]) {
    try {
      const { selectItemsInMyCart } = api.cart()
      const cart = await selectItemsInMyCart(ids)

      if (cart) {
        convertCartData(cart)
      }
    } catch {}
  }

  // async function selectAllProducts () {
  //   await selectCartProduct(cartProductsDeselected.value)
  // }

  async function deselectCartProduct (ids: number[]) {
    try {
      const { deselectItemsInMyCart } = api.cart()
      const cart = await deselectItemsInMyCart(ids)

      if (cart) {
        convertCartData(cart)
      }
    } catch {}
  }

  // async function deselectAllProducts () {
  //   await deselectCartProduct(cartProductsSelected.value)
  // }

  // const cartProductsDeselected = computed(() => {
  //   return cartItems.value
  //     .filter(el => el.status === 1)
  //     .map(el => el.id)
  // })
  //
  // const cartProductsSelected = computed(() => {
  //   return cartItems.value
  //     .filter(el => el.status === 2)
  //     .map(el => el.id)
  // })

  const cartProductsDisabled = computed(() => {
    return cartProducts.value
      .filter(el => el.status === 3)
      .map(el => el.id)
  })

  return {
    cartItemsQuantity,
    cartProductsQuantity,
    cartProductsDisabled,
    cartProducts,
    cartItems,
    cartTotals,

    sendCartEvent,
    getCart,
    getCartBase,
    addProductToCart,
    removeProductFromCart,
    setCartProductAmount,
    emptyCart,
    selectCartProduct,
    deselectCartProduct,
    sendUnexpectedError,

    popoverCartShow: toRef(state.value, 'popoverCartShow'),
    loadingId: toRef(state.value, 'loadingId'),
    helpProduct: toRef(state.value, 'helpProduct')
  }
}
