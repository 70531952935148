import { toRef } from 'vue'
import { useAPI, useHydrationStore } from '#imports'
import { useDevice } from '@/composables/device'

import type { Customer } from '@/modules/nuxt-api/models/Customer'
import { UnauthorizedError } from '@/modules/nuxt-api/models/errors/unauthorized-error'

type State = {
  isVerified: boolean
  customer: Customer | null
  loading: boolean
}

export function useCustomerStore () {
  const api = useAPI()

  const state = useHydrationStore<State>('customer-store', {
    isVerified: false,
    customer: null,
    loading: true
  }, { disableHydration: true })

  async function checkIfExists (login: string) {
    const { checkIfExists } = api.auth.customer()
    return await checkIfExists(login)
  }
  async function checkSignedIn () {
    const { isCrawler } = useDevice()
    const { checkSignedIn } = api.auth.customer()

    if (!isCrawler && await checkSignedIn() === true) {
      state.value.isVerified = true
    } else {
      state.value.isVerified = false
    }
  }
  async function signIn (login: string, password: string, captchaCode: string) {
    const { signIn } = api.auth.customer()

    const response = await signIn(login, password, captchaCode)
    await getUser()
    return response
  }

  async function signUp (
    { email, phone, password, name }: { email: string, phone: string, password: string, name: string },
    captchaCode: string
  ) {
    const { signUp } = api.auth.customer()
    const response = await signUp(email, phone, password, name, captchaCode)

    if (response.hasValidationError()) {
      return response
    } else {
      await getUser()
    }
  }

  async function signOut () {
    const { signOut } = api.auth.customer()
    const error = await signOut()

    if (!error) {
      state.value.customer = null
      state.value.isVerified = false
    } else {
      throw error
    }
  }

  async function resetPassword (login: string, captchaCode: string) {
    const { resetPassword } = api.auth.customer()
    return await resetPassword(login, captchaCode)
  }

  const getUser = async () => {
    try {
      const { getProfileInformation } = useAPI().auth.profile()
      const { profileInformation } = await getProfileInformation()

      state.value.customer = profileInformation as Customer ?? null
      state.value.isVerified = true

      return profileInformation
    } catch (err: any) {
      if (err instanceof UnauthorizedError) {
        state.value.isVerified = false
        state.value.loading = false
      } else {
        throw err
      }
    }
  }

  async function initCustomer () {
    await checkSignedIn()

    if (state.value.isVerified) {
      await getUser()
    } else {
      state.value.customer = null
    }

    state.value.loading = false
  }

  return {
    checkIfExists,
    signIn,
    signUp,
    signOut,
    resetPassword,
    getUser,
    initCustomer,

    isVerified: toRef(state.value, 'isVerified'),
    customer: toRef(state.value, 'customer'),
    loading: toRef(state.value, 'loading')

  }
}
